/**
 * Created by TapFun on 2021/2/4.
 */
import axios from 'axios';
import qs from 'qs';
import { SERVER_HOST } from './../common/portConfig';
import { Dialog } from 'vant'

var instance = axios.create({

});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = localStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    }
);
instance.interceptors.response.use(
    response => {
        if (response.data.code === 409) {
            Dialog.alert({
                title: '温馨提示',
                message: '登录状态失效，请重新登录',
            }).then(() => {
                localStorage.removeItem("Authorization");
                window.location.href = '/';
            });
            return response;

        } else {
            return response
        }
    }, err => {
        return Promise.reject(err)
    }
);


// 获取图片验证码：
export const reqCodeImg = params => { return instance.get(`${SERVER_HOST}/getCodeImage`, { params: params }) };

//下载
export const downloadFileURL = `${SERVER_HOST}/file/downloadFile/`;

// 登录
export const reqWxLogin = params => { return instance.post(`${SERVER_HOST}/wxLogin`,  qs.stringify(params)) };