<template>
    <div class="wrapper">
        <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
            <van-cell v-for="leave in leaveList" :key="leave.id" :label="leave.requestedAt" @click="$router.push({path:'/viewLeave',query:{leaveId:leave.id,type:'CcRecords'}})" is-link>
                <div slot="title">
                    <span>{{leave.name}}</span>
                    <van-tag round plain type="primary">{{leave.typeName}}</van-tag>
                    <van-tag round :type="leave.status===1?'primary':leave.status===2?'warning':leave.status===3?'danger':leave.status===4?'success':leave.status===5?'default':''">{{leave.status===1?'待审批':leave.status===2?'审批中':leave.status===3?'驳回':leave.status===4?'通过':leave.status===5?'已撤销':''}}</van-tag>
                </div>
            </van-cell>
            <div class="van-hairline--bottom"></div>
        </van-list>
    </div>
</template>

<script>
    import {reqGetLeaveCcByUserId} from  "../api/user-api";
    export default {
        data() {
            return {
                leaveList:[],
                loading: false,
                finished: false,
                finishedText:'',
                page: {
                    pageNum: 1,
                    pageSize: 20,
                    total:0
                },
            }
        },
        methods: {
            getLeaveCc(){
                var that=this;
                that.finished = false;
                that.loading = true;
                reqGetLeaveCcByUserId({ pageNum: that.page.pageNum, pageSize: that.page.pageSize}).then(res=>{
                    if(res.data.code===1){
                        that.loading = false;
                        that.page.pageNum ++;
                        that.total=res.data.data.total;
                        let arry = that.leaveList.concat(res.data.data.records);
                        that.leaveList = arry;
                        if(that.leaveList.length===0){
                            that.finishedText='暂无数据';
                        }else{
                            that.finishedText='没有更多了'
                        }
                        if(that.leaveList.length>=that.total){
                            that.finished = true;
                        }
                    }else{
                        that.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                    }
                }).catch(()=> {
                    that.$notify({type: 'danger', duration: '1000', message: "查询失败"});
                })
            },
            onLoad() {
                this.getLeaveCc();
            },
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>