<template>
    <div class="wrapper">
        <div class="content">
            <van-notice-bar mode="closeable">注意：请假获批离校后，一旦返校请假自动终止。</van-notice-bar>
            <van-form v-model="addForm" @submit="onSubmit">
                <van-cell-group>
                    <van-field readonly clickable :value="value" label="请假类型" placeholder="请选择" @click="showPicker = true"
                               :rules="leaveofTypeRules" required/>
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker show-toolbar :columns="typeOfLeavekey" @confirm="onConfirm('leaveofType',$event)" @cancel="showPicker = false"/>
                    </van-popup>
                </van-cell-group>
                <van-cell-group>
                    <van-field readonly clickable :value="addForm.datetimeFrom" label="开始时间" placeholder="请选择" @click="showPicker1 = true" :rules="datetimeFromRules" required/>
                    <van-popup v-model="showPicker1" position="bottom">
                        <van-datetime-picker type="datetime" @confirm="onConfirm('datetimeFrom',$event)" @cancel="showPicker1 = false" :min-date="startMinDate"/>
                    </van-popup>
                </van-cell-group>
                <van-cell-group>
                    <van-field readonly clickable :value="addForm.datetimeTo" label="结束时间" placeholder="请选择" @click="showPicker2 = true" :rules="datetimeToRules" required/>
                    <van-popup v-model="showPicker2" position="bottom">
                        <van-datetime-picker type="datetime" @confirm="onConfirm('datetimeTo',$event)" @cancel="showPicker2 = false" :min-date="endMinDate"/>
                    </van-popup>
                </van-cell-group>

                <van-cell-group>
                    <van-field v-model="addForm.reason" rows="4" autosize label="请假事由" type="textarea" maxlength="100" placeholder="请输入" show-word-limit :rules="reasonRules" required/>
                </van-cell-group>

                <van-cell-group v-if="approveFlag">
                    <van-field readonly v-model="approve.name" label="第一审批人" placeholder="暂无可用审批人" :rules="approveNameRules1" required/>
                </van-cell-group>

                <van-cell-group v-if="!approveFlag">
                    <van-field readonly clickable :value="approve.name" label="第一审批人" placeholder="请选择" @click="showPicker3 = true" :rules="approveNameRules" required/>
                    <van-popup v-model="showPicker3" position="bottom">
                        <van-picker show-toolbar :columns="approveListKey" @confirm="onConfirm('approve',$event)" @cancel="showPicker3 = false"/>
                    </van-popup>
                </van-cell-group>

                <van-cell-group title="选择抄送人">
                    <vanFieldCheckbox placeholder="请选择部门" :columns="deptlist"  v-model="selectDep" :option="{label:'name',value:'externalId'}" @confirm="confirm"/>
                    <vanFieldCheckbox v-if="departmentflag" placeholder="请选择用户"  v-model="selectUser" :columns="listUser" :option="{label:'name',value:'id'}" @confirm="confirm1"/>
                </van-cell-group>
                <div class="submit_btn">
                    <van-button block type="primary" :disabled="isDisabled" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
    import {reqGetDictionaryListByCode, reqGetApprovers, reqGetCc, reqLeaveRequest} from '../api/index';
    import {reqDepatrmentUser} from '../api/user-api'
    import {Toast} from 'vant';
    import vanFieldCheckbox from './vanFieldCheckbox';

    export default {
        components: {
            vanFieldCheckbox
        },
        data() {
            return {
                value2: ['1'],
                value: '',
                showPicker: false,
                showPicker1: false,
                showPicker2: false,
                showPicker3: false,
                showPicker4: false,
                departmentflag: false,
                isDisabled: false,
                approveList: [],
                CcArray: [],
                CcArrayKey: [],
                approveListKey: [],
                approve: {
                    id: "",
                    name: ""
                },
                CcName: "",
                startMinDate: new Date(),
                endMinDate: null,
                typeOfLeave: [],
                approveFlag: true,
                typeOfLeavekey: [],
                addForm: {
                    type: "",
                    datetimeFrom: "",
                    datetimeTo: "",
                    reason: "",
                    approverId: "",
                    approverOpid: "",
                    leaveCcId: "",
                    CcOpid: "",
                    Ccid: "",
                    typeName: "",
                },
                leaveofTypeRules: [{required: true, trigger: 'onBlur'}],
                datetimeFromRules: [{required: true, trigger: 'onBlur'}],
                datetimeToRules: [{required: true, trigger: 'onBlur'}],
                reasonRules: [{required: true, trigger: 'onBlur'}],
                approveNameRules: [{required: true, trigger: 'onBlur'}],
                approveNameRules1: [{required: true, trigger: 'onBlur'}],
                deptlist: [],
                listUser: [],
                selectDep:[],
                selectUser:[],
            }
        },
        methods: {
            myIsNaN(value) {
               return  (typeof value === 'number' && !isNaN(value))
            },
            confirm1(data1, data2) {
                let idArray=[];
             data1.forEach(element => {
                 if(!this.myIsNaN(element)){
                     idArray.push(element)
                 }
             });
            this.addForm.Ccid = idArray.join(',')
            this.selectDep=data1;
            },
            confirm(data1, data2) {
                this.departmentflag = true;
                this.selectUser=data1;
                let users = [];
                data1.forEach(element => {
                    users.push(element)
                });
                reqDepatrmentUser({users: users.join(',')}).then(res => {
                    if (res.data.code === 1) {
                        this.listUser = res.data.data;
                    } else {
                        // Toast('查询失败');
                    }
                }).catch(() => {
                    //    Toast('查询失败');
                });
            },
            dateFtt(fmt, date) {
                var o = {
                    "M+": date.getMonth() + 1,                 //月份
                    "d+": date.getDate(),                    //日
                    "h+": date.getHours(),                   //小时
                    "m+": date.getMinutes(),                 //分
                    "s+": date.getSeconds(),                 //秒
                    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
                    "S": date.getMilliseconds()             //毫秒
                };
                if (/(y+)/.test(fmt))
                    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt))
                        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                return fmt;
            },
            //提交
            onSubmit() {
                reqLeaveRequest(this.addForm).then(res => {
                    this.isDisabled=true;
                    if (res.data.code === 1) {
                        Toast('提交成功');
                        this.$router.replace({path: '/viewLeave?leaveId='+res.data.data.id});
                    } else {
                        Toast('提交失败');
                    }
                }).catch(() => {
                    Toast('提交失败');
                });
            },
            //请假理由
            onConfirm(type, value) {
                if (type == 'leaveofType') {
                    this.value = value;
                    this.showPicker = false;
                    this.typeOfLeave.forEach(element => {
                        if (element.name == value) {
                            this.addForm.type = element.id;
                            this.addForm.typeName = element.name;
                        }
                    });
                } else if (type == 'datetimeFrom') {
                    var d = new Date(value);
                    this.addForm.datetimeFrom = this.dateFtt("yyyy-MM-dd hh:mm", d);
                    this.endMinDate = new Date(new Date(value).getTime() + 60 * 1000);
                    this.addForm.datetimeTo = null;
                    this.showPicker1 = false;
                } else if (type == 'datetimeTo') {
                    var d = new Date(value);
                    this.addForm.datetimeTo = this.dateFtt("yyyy-MM-dd hh:mm", d)
                    this.showPicker2 = false;
                } else if (type == 'approve') {
                    this.approve.name = value
                    this.approveList.forEach(element => {
                        if (element.name == value) {
                            this.addForm.approverOpid = element.openid;
                            this.addForm.approverId = element.id;
                        }
                    });
                    this.showPicker3 = false;
                }
            },
            //获取请假事由
            getDictionaryData() {
                reqGetDictionaryListByCode({code: 'leave_type'}).then(res => {
                    if (res.data.code === 1) {
                        this.typeOfLeave = res.data.data;
                        this.typeOfLeave.forEach(element => {
                            this.typeOfLeavekey.push(element.name)
                        });
                    } else {
                        Toast('查询失败');
                    }
                }).catch(() => {
                    Toast('查询失败');
                });
            },
            //获取第一审批人
            getApprovers() {
                reqGetApprovers({approverGroupId: 5}).then(res => {
                    if (res.data.code === 1) {
                        this.approveList = res.data.data;
                        if (this.approveList.length > 1) {
                            this.approveFlag = false
                            this.approveList.forEach(element => {
                                this.approveListKey.push(element.name)
                            });

                        } else {
                            this.approve.id = this.approveList[0].id
                            this.approve.name = this.approveList[0].name
                            this.addForm.approverId = this.approveList[0].id
                        }
                    } else {
                        Toast('请假审批人未设置');
                    }
                }).catch(() => {
                    Toast('请假审批人未设置');
                });
            },
            //获取抄送人
            getCc() {
                reqGetCc({}).then(res => {
                    if (res.data.code === 1) {
                        this.deptlist = res.data.data.deptlist
                    } else {
                        Toast('查询失败');
                    }
                }).catch(() => {
                    Toast('查询失败');
                });
            }
        },
        mounted() {
            this.endMinDate = new Date(this.startMinDate.getTime() + 60 * 1000);
            this.getDictionaryData();
            this.getApprovers();
            this.getCc();
            this.isDisabled=false;
        }
    }
</script>
<style scoped>

</style>