<template>
    <div class="wrapper">
        <van-notice-bar mode="closeable">注意：请假获批离校后，一旦返校请假自动终止。</van-notice-bar>
        <van-form @submit="onSubmit" v-model="approveFrom">
            <van-cell-group>
                <van-cell title="姓名" :value="approveFrom.requesterName"/>
                <van-cell title="请假类型" :value="approveFrom.typeName"/>
                <van-cell title="开始时间" :value="approveFrom.datetimeFrom"/>
                <van-cell title="结束时间" :value="approveFrom.datetimeTo"/>
                <van-cell title="请假事由" :value="approveFrom.reason"/>
                <van-cell title="审批状态">
                    <van-tag round :type="approveFrom.status==='待审核'?'primary':approveFrom.status==='审核中'?'warning':approveFrom.status==='驳回'?'danger':approveFrom.status==='通过'?'success':approveFrom.status==='已撤销'?'default':''">{{approveFrom.status}}</van-tag>
                </van-cell>
            </van-cell-group>

            <van-cell-group title="第一审批人审批">
                <van-cell title="审批人" :value="firstApproverName"/>
                <div v-if="firstflag">
                    <van-cell title="审批结果" :value="firstResult"/>
                    <van-cell title="审批意见" :value="firstComment"/>
                    <van-cell title="审批时间" :value="firstApprovedAt"/>
                </div>
            </van-cell-group>



            <van-cell-group title="第二审批人审批" v-if="leaveStatus==2 || leaveStatus==4">
                <van-cell title="审批人" :value="secondApproverName"/>
            </van-cell-group>

            <van-cell title="审批结果" v-if="leaveStatus==1 || popUpWindow "  required>
                <van-radio-group direction="horizontal" v-model="submit.status">
                    <van-radio name="3" @click="onChange('3')">同意</van-radio>
                    <van-radio name="2" @click="onChange('2')">不同意</van-radio>
                </van-radio-group>
            </van-cell>
            
            <van-radio-group v-if="leaveStatus==1 || popUpWindow ">
                <van-field v-model="submit.comments" rows="2" autosize label="审批意见" type="textarea" maxlength="100" placeholder="请输入" show-word-limit :rules="[{ required: true, message: '请输入审批意见' }]" required/>
            </van-radio-group>

            <van-cell-group title="选择第二审批人" v-if="leaveStatus==1 && resultFlag">
                <van-field readonly clickable :value="approve.name" label="第二审批人" placeholder="请选择" @click="showPicker3 = true" :rules="[{ required: true, message: '请选择第二审批人' }]" required/>
                <van-popup v-model="showPicker3" position="bottom">
                    <van-picker show-toolbar :columns="approveListKey" @confirm="onConfirm($event)" @cancel="showPicker3 = false"/>
                </van-popup>
            </van-cell-group>

            <van-cell-group title="抄送人">
                <van-cell title="请假抄送给" :value="CcName"/>
            </van-cell-group>

            <div class="submit_btn" v-if="leaveStatus==1 || leaveStatus==2">
                <van-button block type="primary" :disabled="isDisabled" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import {reqLeaveDetail,reqSubmitResult,reqGetApprovalResults} from '../api/user-api'
import {reqGetApprovers} from '../api/index';
import { Toast } from 'vant';
export default {
    data() {
        return {
            leaveId:"",
            approveFrom:"",
            showPicker3:false,
            approveList:[],
            //控制第二审批人数量
            approveFlag:true,
            resultFlag:true,
            approveListKey:[],
            firstApproverName:"",
            secondApproverName:"",
            secondApproveList:"",
            firstComment:"",
            isDisabled: false,
            CcName:"",
            firstResult:"",
            firstApprovedAt:"",
            leaveStatus:"",
            firstflag:false,
            popUpWindow:false,
            approve:{
                id:"",
                name:""
            },
            studentId:"",
            submit:{
                id:null,
                approver:null,
                leaveId:null,
                status:'3',
                comments:null,
                secondApprover:null
            },
            numberOfApprovals:"",
            secondApproverFlag:false,
        }
    },
    methods:{
        onChange(result){
            if(result==3){
                this.resultFlag=true
            }else{
                this.resultFlag=false
            }
        },
        // 确认第二审批人
        onConfirm(value){
            this.approve.name=value
            this.secondApproveList.forEach(element => {
              if(element.name==value){
                  this.submit.secondApprover=element.id;
              }
            });
            this.showPicker3=false;
        },
        //提交
        onSubmit(){
            this.isDisabled=true;
            reqSubmitResult(this.submit).then(res=>{
                    if(res.data.code===1){
                        Toast('提交成功');
                        this.$router.push({path:this.$route.query.from?this.$route.query.from:'/userCenter'});
//                        this.$router.push({path: this.$route.params.from});
                    }else{
                        Toast('提交失败');
                    }
            }).catch(() => {
                Toast('提交失败');
            });
        },
         //获取请假详情
        getLeaveDetails(){
            this.leaveId=this.$route.query.leaveId
            this.submit.leaveId=this.leaveId
            reqLeaveDetail({leaveId:this.leaveId}).then(res=>{
                if(res.data.code===1){
                    this.CcName=res.data.data.CcName
                    this.approveFrom=res.data.data.baselist;
                    this.approveList=res.data.data.mapList;
                    let approverGroupIdFlag=res.data.data.approverGroupIdFlag;
                    this.leaveStatus=this.approveFrom.status;
                    if( this.approveFrom.status==1){
                         this.approveFrom.status='待审核'
                    }else if(this.approveFrom.status==2){
                        this.approveFrom.status='审核中'
                    }else if(this.approveFrom.status==3){
                        this.approveFrom.status='驳回'
                    }else if(this.approveFrom.status==4){
                        this.approveFrom.status='通过'
                    }else if(this.approveFrom.status==5){
                        this.approveFrom.status='已撤销'
                    }
                    let firstNumberFlag=false;
                    let secondtNumberFlag=false;
                    res.data.data.mapList.forEach(element =>{
                        if(element.approverGroupId==5){
                            this.firstApproverName=element.name;
                             firstNumberFlag=true;
                            if(Boolean(element.comments)){
                               
                                this.firstflag=true;
                                this.firstComment=element.comments;
                                if(element.status==3){
                                    this.firstResult='同意'
                                }else if(element.status==2){
                                    this.firstResult='驳回'
                                }
                                this.firstApprovedAt=element.approvedAt
                            }else{
                                this.firstflag=false;
                            }
                        }
                        if(element.approverGroupId==6){
                            this.secondApproverName=element.name;
                             secondtNumberFlag=true;
                            this.submit.approver=element.id;
                            if(Boolean(element.comments)){
                                this.popUpWindow=false
                            }else{
                                  this.popUpWindow=true
                            }
                        }
                        if(firstNumberFlag==true && secondtNumberFlag==false){
                             res.data.data.mapList.forEach(element => {
                                if(element.approverGroupId==5){
                                  this.submit.approver=element.id;
                                  this.submit.id=element.lahId;
                                }
                            });
                        }
                         if(firstNumberFlag==true && secondtNumberFlag==true){
                             res.data.data.mapList.forEach(element => {
                                if(element.approverGroupId==6){
                                 this.submit.approver=element.id;
                                  this.submit.id=element.lahId;
                                }
                            });
                        }
                    });
                    this.studentId=this.approveFrom.requester
                    this.getApprovers();
                  
                }else{
                    Toast('提交失败');
                }
            }).catch(() =>{
                Toast('提交失败');
            });      
        },
        //获取第二审批人
         getApprovers(){
            reqGetApprovers({userId:this.studentId,approverGroupId:6}).then(res=>{
                    if(res.data.code===1){
                        this.secondApproveList=res.data.data;
                            this.secondApproveList.forEach(element => {
                                this.approveListKey.push(element.name)
                            });
                    }
            }).catch(() => {
                Toast('查询失败');
            });
        },
        //获取当前登录教师是否审批过该假条
        getApprovalResults(){
            this.leaveId=this.$route.query.leaveId
            reqGetApprovalResults({leaveId:this.leaveId}).then(res=>{
                if(res.data.code==1){
                    this.approvalResultsFlag=res.data.data
                }
            }).catch(() => {
                Toast('查询失败');
            });
        }
    },
    mounted() {
        this.getLeaveDetails();
        this.getApprovalResults();
          this.isDisabled=false;
    },
}
</script>
<style scoped>

</style>