import Vue from 'vue'
import Store from '../store/index'
import VueRouter from 'vue-router'
import Index from '../views/index'
import login from '../views/login.vue'
import applyRequest from '../views/applyRequest.vue'
import basicInfo from '../views/basicInfo.vue'
import ccRecords from '../views/ccRecords.vue'
import accessRecords from '../views/accessRecords.vue'
import applyRecords from '../views/applyRecords.vue'
import helpCenter from '../views/helpCenter.vue'
import userCenter from '../views/userCenter.vue'
import approveRecords from '../views/approveRecords.vue'
import leaveDetails from '../views/leaveDetails.vue'
import viewLeave from '../views/viewLeave.vue'
import editPassword from '../views/editPassword.vue'


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        meta: {title: '登录'},
        component: login
    },
    {
        path: '/index',
        name: 'index',
        meta: {title: '首页'},
        component: Index
    },
    {
        path: '/accessRecords',
        name: 'accessRecords',
        meta: {title: '开门记录'},
        component: accessRecords
    },
    {
        path: '/applyRecords',
        name: 'applyRecords',
        meta: {title: '请假记录'},
        component: applyRecords
    },
    {
        path: '/applyRequest',
        meta: {title: '申请请假'},
        name: 'applyRequest',
        component: applyRequest
    },
    {
        path: '/basicInfo',
        name: 'basicInfo',
        meta:{title:'个人信息'},
        component: basicInfo
    },
    {
        path: '/helpCenter',
        name: 'helpCenter',
        meta:{title:'帮助中心'},
        component: helpCenter
    },
    {
        path: '/userCenter',
        name: 'userCenter',
        meta:{title:'用户中心'},
        component: userCenter
    },
    {
        path: '/approveRecords',
        name: 'approveRecords',
        meta:{title:'审批记录'},
        component: approveRecords
    },
    {
        path: '/leaveDetails',
        name: 'leaveDetails',
        meta:{title:'请假详情'},
        component: leaveDetails
    },
    {
        path: '/viewLeave',
        name: 'viewLeave',
        meta:{title:'请假详情'},
        component: viewLeave
    },
    {
        path: '/ccRecords',
        name: 'ccRecords',
        meta:{title:'抄送记录'},
        component: ccRecords
    },
    {
        path: '/editPassword',
        name: 'editPassword',
        meta:{title:'修改密码'},
        component: editPassword
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from,next) => {
    if(to.name=='basicInfo'&& from.name=='login'){
        next(false)
    }else{
        if(to.meta.title){
             Store.dispatch('changeTitle', to.meta.title)
        }
        next()
    }
})
export default router
