<template>
    <div class="wrapper">
        <div class="logo">
            <img src="../assets/img/logo.png" alt="请假审批">
        </div>
        <div class="login">
            <van-form @submit="handleLogin">
                <div class="login_form">
                    <van-field v-model="username" name="用户名" label="用户名" placeholder="请填写" :rules="[{ required: true, message: '请填写用户名' }]"/>
                    <van-field class="password" v-model="password" type="password" name="密码" label="密码" placeholder="请填写" :rules="[{ required: true, message: '请填写密码' }]"/>
                    <van-field v-model="captcha" maxlength="4" name="验证码" label="验证码" placeholder="请填写" :rules="[{ required: true, message: '请填写验证码' }]">
                        <template #button>
                            <img width="100" height="32" id="myCodeImg" @click="showCodeImg"/>
                        </template>
                    </van-field>
                </div>
                <div class="submit_btn">
                    <van-button type="primary" native-type="submit" block>登录</van-button>
                </div>
            </van-form>
        </div>
        <div class="footer">
            <div class="copyright">Copyright &copy; 2021 北京电子信息技师学院</div>
        </div>
    </div>
</template>
<script>
    import {reqCodeImg,downloadFileURL,reqWxLogin} from "../api/login-api";

    export default {
    data() {
        return {
            downloadFileURL:downloadFileURL,
            username: '',
            password: '',
            captcha:'',
            checked: false,//记住密码开关
            logining: false,
            show: true,
            captchaImage:'',
            code:'',
        };
    },
    methods: {
        //展示验证码
        showCodeImg () {
            reqCodeImg().then((res) => {
                if(res.data.code===1){
                    document.getElementById('myCodeImg').src = downloadFileURL + res.data.data.fileName + '.jpg/';
                    this.captchaImage=res.data.data.onlyImgCode;
                }
            })
        },
        handleLogin() {
            this.logining = true;
            var password = this.encode64(this.encode64(this.password) + 'pd');
            var username = this.encode64(this.encode64(this.username) + 'un');
            var loginParams = {username: username, password: password,captcha:this.captcha,captchaImage:this.captchaImage,code:this.code};
            reqWxLogin(loginParams).then(data => {
                if (data.data.code === 1) {
                    localStorage.setItem('Authorization', data.data.data.token);
                    localStorage.setItem('position', data.data.data.position);

                    this.$router.replace({path:"/index"});
                    this.$notify({ type: 'success', duration: '1000', message: data.data.msg });
                }else {
                    this.$notify({ type: 'danger', duration: '1000', message: data.data.msg });
                    this.showCodeImg ();
                }
            }) .catch(() => {
                this.logining = false;
                this.showCodeImg ();
                this.$notify({ type: 'danger', duration: '1000', message: '登录异常' });
            })
        },
        // base64加密开始
        encode64(input) {
            var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
                + "wxyz0123456789+/" + "=";
            var output = "";
            var chr1, chr2, chr3 = "";
            var enc1, enc2, enc3, enc4 = "";
            var i = 0;
            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                    + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
            } while (i < input.length);

            return output;
        },

    },
    mounted(){
        if(localStorage.getItem('Authorization') && localStorage.getItem('position')){
            this.$router.replace({path:"/index"});
        }else{
            var row = this.$route.query;
            this.code=row.code;
            this.showCodeImg();
        }
    }
};
</script>

<style lang="less" scoped>

</style>