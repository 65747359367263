<template>
    <div class="wrapper">
        <div class="content">
            <van-cell-group>
                <van-cell title="所在校区" :value="userInfo.location" />
                <van-cell title="姓名" :value="userInfo.name" />
                <van-cell title="班级" :value="userInfo.departmentName" />
                <van-cell title="职务" :value="userInfo.position" />
                <van-cell title="手机号" :value="userInfo.mobile" />
                <van-cell title="录入时间" :value="userInfo.createTime" />
            </van-cell-group>

            <div class="submit_btn">
                <van-button type="warning" @click="goBack" block>退出登录</van-button>
            </div>
        </div>
    </div>
</template>
<script>
    import {reqUserExternalOne} from '../api/user-api'
    import {downloadFileURL} from "../api/login-api";
    import { Dialog } from 'vant';
    export default {
        data() {
            return {
                active: 1,
                downloadFileURL: downloadFileURL,
                userInfo: {
                    avatar: '',
                    name: '',
                    mobile: '',
                }
            }
        },
        methods: {
            //退出登录
            goBack(){
                Dialog.confirm({
                    title: '温馨提示',
                    message: '确认退出登录？',
                })
                    .then(() => {
                        sessionStorage.removeItem("Authorization");
                        sessionStorage.removeItem("position")
                        localStorage.removeItem('Authorization');
                        localStorage.removeItem('position');
                        this.$router.push({path:"/"});
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            //查询用户信息
            getUserOne() {
                reqUserExternalOne().then(res => {
                    if (res.data.code === 1) {
                        this.userInfo = res.data.data;
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: "查询失败"});
                    }
                }).catch(() => {
                    this.$notify({type: 'danger', duration: '1000', message: "查询失败"});
                })
            }
        },
        mounted() {
            this.getUserOne();
        }
    }
</script>

<style scoped>
    .user {
        margin: 15px;
        font-size: 16px;
    }

    .avatar {
        background-color: #f0f0f0;
        border-radius: 50%;
        display: flex;
        justify-content: flex-start;
    }
    .desc{

    }

</style>