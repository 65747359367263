/**
 * Created by TapFun on 2021/2/4.
 */
import axios from 'axios';
import qs from 'qs';
import { SERVER_HOST } from './../common/portConfig';

import { Dialog } from 'vant'

var instance = axios.create({

});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = localStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    }
);
instance.interceptors.response.use(
    response => {
        if (response.data.code === 409) {
            Dialog.alert({
                title: '温馨提示',
                message: '登录状态失效，请重新登录',
            }).then(() => {
                localStorage.removeItem("Authorization");
                window.location.href = '/';
            });
            return response;

        } else {
            return response
        }
    }, err => {
        return Promise.reject(err)
    }
);

//根据code获取字典数据
export const reqGetDictionaryListByCode = params => {
    return instance.get(`${SERVER_HOST}/dictionary/getListByCode`, {
        params: params
    })
};
//获取审批人
export const reqGetApprovers = params => {
    return instance.get(`${SERVER_HOST}/leave/getApproves`, {
        params: params
    })
};
//获取抄送人
export const reqGetCc = params => {
    return instance.get(`${SERVER_HOST}/userExternal/allUserExternal`, {
        params: params
    })
};
//发送请假请求
export const reqLeaveRequest = params => {
    return instance.post(`${SERVER_HOST}/leave/leaveRequest`,{
         params
    })
};

//首页获取请假记录
export const reqGetWxLeave = params => {
    return instance.get(`${SERVER_HOST}/leave/getWxLeave`, {
        params: params
    })
};


//首页获取用户信息
export const reqGetWxIndexUser = params => {
    return instance.get(`${SERVER_HOST}/userExternal/wxIndexUser`, {
        params: params
    })
};



