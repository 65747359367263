<template>
    <div class="wrapper">
        <div class="content">
            <div class="html_content" v-html="helpCenter.content" v-if="helpCenter.content"></div>
            <div class="van-list__finished-text" v-else>暂无数据</div>
        </div>
    </div>
</template>

<script>
    import {reqGetHelpCenter} from "../api/user-api";

    export default {
        data() {
            return {
                helpCenter:{}
            }
        },
        methods: {
            getHelpCenter(){
                reqGetHelpCenter().then(res=>{
                    if(res.data.code===1){
                        this.helpCenter=res.data.data;
                    }
                }).catch(()=> {
                    this.$notify({type: 'danger', duration: '1000', message: "查询失败"});
                })
            }
        },
        mounted() {
            this.getHelpCenter();
        }
    }
</script>

<style scoped>


</style>