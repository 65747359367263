<template>
    <div class="wrapper">
        <van-tabs v-model="active" @click="onClick">
            <van-tab title="待审批">
                <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
                    <van-cell v-for="item in list" :key="item.id" is-link @click="jumpPage(item,'pending')" :label="'申请时间:'+item.requestedAt">
                        <template #title>
                            <span>{{item.requesterName}}</span>
                            <van-tag round plain type="primary">{{item.typeName}}</van-tag>
                        </template>
                    </van-cell>
                    <div class="van-hairline--bottom"></div>
                </van-list>
            </van-tab>
            <van-tab title="已审批">
                <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
                    <van-cell v-for="item in list" :key="item.id" is-link @click="jumpPage(item,'approver')" :label="'申请时间:'+item.requestedAt">
                        <template #title>
                            <span>{{item.requesterName}}</span>
                            <van-tag round plain type="primary">{{item.typeName}}</van-tag>
                        </template>
                    </van-cell>
                </van-list>
            </van-tab>
        </van-tabs>
   </div>
</template>
<script>
import { reqApprovalRecord } from '../api/user-api';
export default {
    data() {
        return {
             active: 0,
            list:[],
            loading: false,
            finished: false,
            finishedText:'',
            status:1,
            page: {
                pageNum: 1,
                pageSize: 20,
                total:0
            },
        }
    },
    methods: {
        onClick(name, title) {
            this.active=name;
            this.page.pageNum=1;
            this.finished = false;
            this.loading = true;
            this.list=[];
            if(this.active===0){
                this.status=1;
            }else{
                this.status=4;
            }
            this. getApprovalRecord()
        },
        jumpPage(item,status){
            if(status==='pending'){
                this.$router.push({path:'/leaveDetails',query:{leaveId:item.id, from: '/approveRecords'}});
//                this.$router.push({path:'/leaveDetails', name: 'leaveDetails', query:{leaveId:item.id}, params:{from: '/approveRecords'}});
            }
            if(status==='approver'){
                this.$router.push({path:'/viewLeave',query:{leaveId:item.id}});
            }
        },
        onLoad() {
            this.getApprovalRecord();
        },
        //获取审批记录
        getApprovalRecord(){
            var that=this;
            that.finished = false;
            that.loading = true;
            reqApprovalRecord({pageNum: that.page.pageNum, pageSize: that.page.pageSize,status:that.status}).then(res=>{
                    if(res.data.code===1){
                        that.loading = false;
                        that.page.pageNum ++;
                        that.total=res.data.data.total;
                        let arry = that.list.concat(res.data.data.records);
                        that.list = arry;
                        if(that.list.length===0){
                            that.finishedText='暂无数据';
                        }else{
                            that.finishedText='没有更多了'
                        }
                        if(that.list.length>=that.total){
                            that.finished = true;
                        }
                    }else{
                        this.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                    }
            }).catch(() => {
                this.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
            });
        }
        
    },
    mounted() {
        this.getApprovalRecord();
    },
}
</script>
