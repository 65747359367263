<template>
    <div class="wrapper">
        <div class="content">
            <van-form @submit="onSubmit">
                <van-cell-group>
                    <van-field v-model="form.oldPwd" type="password" name="原密码" label="原密码" placeholder="请输入" required :rules="[{ required: true, message: '请填写原密码' }]"/>
                    <van-field v-model="form.newPwd" type="password" name="新密码" label="新密码" placeholder="请输入" required :rules="[{ required: true, message: '请填写新密码' },{pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/, message:'密码由字母和数字组成6到16位',trigger: 'blur'},]"/>
                    <van-field v-model="form.confirmPwd" type="password" name="确认新密码" label="确认新密码" placeholder="请输入" required :rules="[{ required: true, message: '请填写确认新密码' }]"/>
                </van-cell-group>
                <div class="submit_btn"><van-button type="primary" native-type="submit" block>确认</van-button></div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import {updateExternalUserPwd} from "../api/user-api";

    export default {
        data() {
            return {
                form:{
                    oldPwd:'',
                    newPwd:'',
                    confirmPwd:'',
                }
            }
        },
        methods: {

            // base64加密开始
            encode64(input) {
                var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
                    + "wxyz0123456789+/" + "=";
                var output = "";
                var chr1, chr2, chr3 = "";
                var enc1, enc2, enc3, enc4 = "";
                var i = 0;
                do {
                    chr1 = input.charCodeAt(i++);
                    chr2 = input.charCodeAt(i++);
                    chr3 = input.charCodeAt(i++);
                    enc1 = chr1 >> 2;
                    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                    enc4 = chr3 & 63;
                    if (isNaN(chr2)) {
                        enc3 = enc4 = 64;
                    } else if (isNaN(chr3)) {
                        enc4 = 64;
                    }
                    output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                        + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                    chr1 = chr2 = chr3 = "";
                    enc1 = enc2 = enc3 = enc4 = "";
                } while (i < input.length);

                return output;
            },
            //修改密码
            onSubmit(){
                if(this.form.confirmPwd===this.form.newPwd){
                    var oldPwd = this.encode64(this.encode64(this.form.oldPwd) + 'oldPwd');
                    var newPwd = this.encode64(this.encode64(this.form.newPwd) + 'newPwd');
                    var confirmPwd = this.encode64(this.encode64(this.form.confirmPwd) + 'confirmPwd');
                    updateExternalUserPwd({token:this.token,oldPwd:oldPwd,newPwd:newPwd,confirmPwd:confirmPwd}).then((res) => {
                        if(res.data.code===7){
                            this.$notify({ type: 'danger', duration: '1000', message: res.data.msg });
                            this.form.oldPwd='';
                            this.form.newPwd='';
                            this.form.confirmPwd='';
                        }else if(res.data.code===1){
                            this.$notify({ type: 'success', duration: '1000', message: res.data.msg });
                            //修改密码后重新登录L
                            this.$router.push('/');
                            sessionStorage.removeItem("position");
                            sessionStorage.removeItem('Authorization');
                        }else if(res.data.code===2){
                            this.$notify({ type: 'danger', duration: '1000', message: res.data.msg });
                            this.form.newPwd='';
                            this.form.confirmPwd='';
                        }else {
                            this.$notify({ type: 'danger', duration: '1000', message: res.data.msg });
                            this.form.oldPwd='';
                            this.form.newPwd='';
                            this.form.confirmPwd='';
                        }
                    });
                }else{
                    this.$notify({ type: 'danger', duration: '1000', message: "新密码和确认新密码不一致！" });
                    this.form.newPwd='';
                    this.form.confirmPwd='';
                }
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>