<template>
    <div class="wrapper tab_bar">
        <div class="header">
            <div class="user_info" slot="title">
                <div class="avatar">
                    <img width="48" height="48" :src="userInfo.avatar?downloadFileURL+userInfo.avatar+'.jpeg/':require('../assets/img/avatar.png')"/>
                </div>
                <div class="name_department">
                    <div class="user_name">{{userInfo.name}}</div>
                    <div class="department">{{userInfo.mobile}}</div>
                </div>
            </div>
        </div>
        <div class="content">
            <van-cell-group title="基本信息">
                <van-cell title="个人资料" is-link url="/basicInfo"/>
                <van-cell title="修改密码" is-link url="/editPassword"/>
            </van-cell-group>
            <van-cell-group title="请假审批" class="van-cell__tag">
                <van-cell title="请假记录" is-link url="/applyRecords" v-if="position==='学生'">
                    <van-tag round plain type="primary">{{userInfo.leaveCount}}</van-tag>
                </van-cell>
                <van-cell title="审批记录" is-link url="/approveRecords" v-if="position!=='学生'">
                    <van-tag round plain type="primary">{{userInfo.approvalHistoryCount}}</van-tag>
                </van-cell>
                <van-cell title="抄送记录" is-link url="/ccRecords">
                    <van-tag round plain type="primary">{{userInfo.ccCount}}</van-tag>
                </van-cell>
                <van-cell title="开门记录" is-link url="/accessRecords" v-if="position==='学生'">
                    <van-tag round plain type="primary">{{userInfo.passingRecordCount}}</van-tag>
                </van-cell>
            </van-cell-group>
            <van-cell-group title="其它相关">
                <van-cell title="帮助中心" is-link url="/helpCenter"/>
            </van-cell-group>

            <div class="footer">
                <div class="copyright">Copyright &copy; 2021 北京电子信息技师学院</div>
            </div>

        </div>
        <div class="footer">
            <van-tabbar v-model="active">
                <van-tabbar-item icon="wap-home" @click="goIndex">首页</van-tabbar-item>
                <van-tabbar-item icon="manager">我的</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
    import {reqUserExternalOne} from '../api/user-api'
    import {downloadFileURL} from "../api/login-api";
    export default {
        data() {
            return {
                active:1,
                position:'',
                downloadFileURL:downloadFileURL,
                userInfo:{
                    avatar:'',
                }
            }
        },
        methods: {
            goIndex(){
                this.$router.push({path:"/index"});
            },
            //查询用户信息
            getUserOne(){
                reqUserExternalOne().then(res=>{
                    if(res.data.code===1){
                        this.userInfo=res.data.data;
                    }else{
                        this.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                    }
                }).catch(()=>{
                    this.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                })
            }
        },
        mounted() {
            this.position=localStorage.getItem('position');
            this.getUserOne();
        }
    }
</script>

<style scoped>

</style>