<template>
    <div class="wrapper">
        <van-form v-model="approveFrom">
            <van-cell-group>
                <van-cell title="姓名" :value="approveFrom.requesterName"/>
                <van-cell title="请假类型" :value="approveFrom.typeName"/>
                <van-cell title="开始时间" :value="approveFrom.datetimeFrom"/>
                <van-cell title="结束时间" :value="approveFrom.datetimeTo"/>
                <van-cell title="请假事由" :value="approveFrom.reason"/>
                <van-cell title="审批状态">
                    <van-tag round :type="approveFrom.status==='待审批'?'primary':approveFrom.status==='审批中'?'warning':approveFrom.status==='驳回'?'danger':approveFrom.status==='通过'?'success':approveFrom.status==='已撤销'?'default':''">{{approveFrom.status}}</van-tag>
                </van-cell>
                <van-cell title="提交时间" :value="approveFrom.requestedAt"/>
            </van-cell-group>

            <van-cell-group title="第一审批人" v-if="approveList && approveList.length > 0">
                <van-cell title="审批人" :value="approveList[0].approver"/>
            </van-cell-group>

            <van-cell-group v-if="approveList && approveList.length > 0">
                <van-cell title="审批结果" :value="approveList[0].status===1?'待审批':approveList[0].status===2?'驳回':approveList[0].status===3?'通过':''"/>
                <van-cell title="审批意见" :value="approveList[0].comments"/>
                <van-cell title="审批时间" :value="approveList[0].approvedAt"/>
            </van-cell-group>

            <van-cell-group title="第二审批人" v-if="approveList && approveList.length === 2">
                <van-cell title="第二审批人" :value="approveList[1].approver"/>
            </van-cell-group>

            <van-cell-group v-if="approveList && approveList.length === 2">
                <van-cell title="审批结果" :value="approveList[1].status===1?'待审批':approveList[1].status===2?'驳回':approveList[1].status===3?'通过':''"/>
                <van-cell title="审批意见" :value="approveList[1].comments"/>
                <van-cell title="审批时间" :value="approveList[1].approvedAt"/>
            </van-cell-group>

            <van-cell-group title="抄送人">
                <van-cell title="请假抄送给" :value="CcName"/>
            </van-cell-group>


            <div class="submit_btn" v-if="leaveStatus==1 &&  bellyFlag  ">
                <van-button block type="danger" @click="applicationCanceled">撤销申请</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import {reqLeaveView,reqSubmitResult,reqGetApprovalResults,reqapplicationCanceled,reqgetBellyFlag} from '../api/user-api'
import {reqGetApprovers} from '../api/index';
import { Dialog } from 'vant'
import { Toast } from 'vant';
export default {
    data() {
        return {
            leaveId:"",
            approveFrom:"",
            showPicker3:false,
            approveList:[],
            //控制第二审批人数量
            approveFlag:true,
            approvalResultsFlag:false,
            resultFlag:false,
            approveListKey:[],
            firstApproverName:"",
            secondApproverName:"",
            secondApproveList:"",
            secondComment:"",
            secondResult:"",
            secondApprovedAt:"",
            secondturnDown:false,
            secondFlag:false,
            firstflag:false,
            popUpWindow:false,
            firstComment:"",
            firstResult:"",
            firstApprovedAt:"",
            leaveStatus:"",
            approve:{
                id:"",
                name:""
            },
            CcName:'',
            studentId:"",
            secondApproverFlag:false,
            bellyFlag:false,
            CcPeopleFlag:false,
        }
    },
    methods:{
         //判断当前用户是否拥有该假条
        getbellyFlag(){
            reqgetBellyFlag({leaveId:this.leaveId}).then(res=>{
                if (res.data.code === 1) {
                    this.bellyFlag=res.data.data;
                }
            })
        },
        //撤销申请
        applicationCanceled(){
            Dialog.confirm({
                title: '温馨提示',
                message: '确认撤销？',
            }).then(() => {
                reqapplicationCanceled({leaveId:this.leaveId}).then(res => {
                    if (res.data.code === 1) {
                        Toast('撤销成功');
                        this.getLeaveDetails();
                    } else {
                        Toast('撤销失败');
                    }
                }).catch(() => {
                    Toast('撤销失败');
                });
            }).catch(() => {

            });
        },
         //获取请假详情
        getLeaveDetails(){
            this.leaveId=this.$route.query.leaveId
            reqLeaveView({leaveId:this.leaveId}).then(res=>{
                if(res.data.code===1){
                    this.approveFrom=res.data.data.baselist;
                    this.approveList=res.data.data.mapList;
                    this.leaveStatus=this.approveFrom.status;
                    this.CcName=res.data.data.CcName;
                    //let approverGroupIdFlag=res.data.data.approverGroupIdFlag;
                    if( this.approveFrom.status==1){
                         this.approveFrom.status='待审批'
                    }else if(this.approveFrom.status==2){
                        this.approveFrom.status='审批中'
                    }else if(this.approveFrom.status==3){
                        this.approveFrom.status='驳回'
                    }else if(this.approveFrom.status==4){
                        this.approveFrom.status='通过'
                    }else if(this.approveFrom.status==5){
                        this.approveFrom.status='已撤销'
                    }

                    /*
                    res.data.data.mapList.forEach(element =>{
                        if(element.approverGroupId==5){
                            this.firstApproverName=element.name;
                            if(Boolean(element.comments)){
                                this.firstflag=true;
                                this.firstComment=element.comments;
                                if(element.status==3){
                                    this.firstResult='同意'
                                }else if(element.status==2){
                                    this.firstResult='驳回'
                                }
                                this.firstApprovedAt=element.approvedAt
                            }else{
                                this.firstflag=false;
                            }
                        }
                        if(element.approverGroupId==6){
                            this.secondApproverName=element.name;
                            if(Boolean(element.comments)){
                                this.popUpWindow=false
                                this.secondFlag=true;
                                this.secondComment=element.comments;
                                if(element.status==3){
                                    this.secondResult='同意'
                                }else if(element.status==2){
                                    this.secondResult='驳回'
                                }
                                this.secondApprovedAt=element.approvedAt
                            }else{
                                  this.popUpWindow=true
                            }
                        }
                    });
                    */
                    this.studentId=this.approveFrom.requester
                }else{
                    // Toast('发送失败');
                }
            }).catch(() =>{
                // Toast('发送失败');
            });
        },
    },
    mounted() {
        if(this.$route.query.type=='CcRecords'){
           this.CcPeopleFlag=true
        }
        this.getLeaveDetails();
        this.getbellyFlag();
    },
}
</script>
<style scoped>

</style>