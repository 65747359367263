<template>
    <div class="wrapper">
        <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
            <van-cell v-for="passingRecord in passingRecordList" :key="passingRecord.id" :title="passingRecord.passedAt+'请求'+(passingRecord.direction===1?'进门':passingRecord.direction===2?'出门':'未知')">
                <div>
                    {{passingRecord.abnormalType===0?'成功':passingRecord.abnormalType===10001?'人证不匹配':passingRecord.abnormalType===10002?'人卡不匹配':passingRecord.abnormalType===10003?'人码不匹配':passingRecord.abnormalType===20001?'访客不在有效期内':passingRecord.abnormalType===20002?'不在通行时间内':passingRecord.abnormalType===30001?'无效身份证':passingRecord.abnormalType===30002?'无效IC卡':passingRecord.abnormalType===30003?'无效二维码':passingRecord.abnormalType===40001?'体温异常':'未知'}}
                </div>
            </van-cell>
            <div class="van-hairline--bottom"></div>
        </van-list>
    </div>
</template>
<script>
    import {reqGetPassingRecordByUserId} from "../api/user-api";

    export default {
        data() {
            return {
                passingRecordList:[],
                loading: false,
                finished: false,
                finishedText:'',
                page: {
                    pageNum: 1,
                    pageSize: 20,
                    total:0
                },
            }
        },
        methods: {

            getPassingRecord(){
                var that=this;
                that.finished = false;
                that.loading = true;
                reqGetPassingRecordByUserId({ pageNum: that.page.pageNum, pageSize: that.page.pageSize}).then(res=>{
                    if(res.data.code===1){
                        that.loading = false;
                        that.page.pageNum ++;
                        that.total=res.data.data.total;
                        let arry = that.passingRecordList.concat(res.data.data.records);
                        that.passingRecordList = arry;
                        if(that.passingRecordList.length===0){
                            that.finishedText='暂无数据';
                        }else{
                            that.finishedText='没有更多了'
                        }
                        if(that.passingRecordList.length>=that.total){
                            that.finished = true;
                        }
                    }else{
                        that.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                    }
                }).catch(()=> {
                    that.$notify({type: 'danger', duration: '1000', message: "查询失败"});
                })
            },
            onLoad() {
                this.getPassingRecord();
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>


</style>