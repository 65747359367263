import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vant from 'vant';
import moment from 'moment';
// import 'vant/lib/index.css';
import './assets/css/style.less';

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.use(vant);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
