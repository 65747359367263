<template>
    <div class="wrapper">
        <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
            <van-cell v-for="leave in LeaveList" :key="leave.id" @click="$router.push({path:'/viewLeave',query:{leaveId:leave.id}})">
                <div slot="title">
                    {{leave.reason}}
                    <van-tag round plain type="primary">{{leave.leaveType}}</van-tag>
                    <van-tag round :type="leave.status===1?'primary':leave.status===2?'warning':leave.status===3?'danger':leave.status===4?'success':leave.status===5?'default':''">{{leave.status===1?'待审批':leave.status===2?'审批中':leave.status===3?'驳回':leave.status===4?'通过':leave.status===5?'已撤销':''}}</van-tag>
                </div>
                <div slot="label">
                    {{leave.datetimeFrom + ' 至 ' + leave.datetimeTo}}
                </div>
            </van-cell>
            <div class="van-hairline--bottom"></div>
        </van-list>
    </div>
</template>
<script>
    import {reqGetWxLeave} from "../api/index";

    export default {
        data() {
            return {
                LeaveList:[],
                loading: false,
                finished: false,
                finishedText:'',
                page: {
                    pageNum: 1,
                    pageSize: 20,
                    total:0
                },
            }
        },
        methods: {

            getLeave(){
                var that=this;
                that.finished = false;
                that.loading = true;
                reqGetWxLeave({ pageNum: that.page.pageNum, pageSize: that.page.pageSize}).then(res=>{
                    if(res.data.code===1){
                        that.loading = false;
                        that.page.pageNum ++;
                        that.total=res.data.data.total;
                        let data = res.data.data.records;
                        data.forEach(item => {
                            item.datetimeFrom = this.$moment(item.datetimeFrom).format("YYYY-MM-DD HH:mm");
                            item.datetimeTo = this.$moment(item.datetimeTo).format("YYYY-MM-DD HH:mm");
                        })
                        let arry = that.LeaveList.concat(data);
                        that.LeaveList = arry;
                        if(that.LeaveList.length===0){
                            that.finishedText='暂无数据';
                        }else{
                            that.finishedText='没有更多了'
                        }
                        if(that.LeaveList.length>=that.total){
                            that.finished = true;
                        }
                    }else{
                        that.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                    }
                }).catch(()=> {
                    that.$notify({type: 'danger', duration: '1000', message: "查询失败"});
                })
            },
            onLoad() {
                this.getLeave();
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>