<template>
    <div class="wrapper tab_bar">
        <div class="header">
            <van-cell is-link to="/userCenter">
                <div class="user_info" slot="title">
                    <div class="avatar">
                        <img width="48" height="48" :src="userInfo.avatar?downloadFileURL+userInfo.avatar+'.jpeg/':require('../assets/img/avatar.png')"/>
                    </div>
                    <div class="name_department">
                        <div class="user_name">{{userInfo.name}}</div>
                        <div class="department">{{userInfo.mobile}}</div>
                    </div>
                </div>
            </van-cell>
        </div>
        <div class="content">
            <div class="date_request">
                <div :class="position==='学生'?'date_request_wrapper student':'date_request_wrapper teacher'">
                    <div class="current_date">
                        <div class="icon"><van-icon name="calendar-o" size="44" /></div>
                        <div class="date">
                            <div class="text">当前时间</div>
                            <div class="time">{{nowDate}}</div>
                        </div>
                    </div>
                    <div class="request_btn">
                        <a @click="goLeave">{{position==='学生'?'请假':'审批'}}</a>
                    </div>
                </div>
            </div>
            <div class="apply_request" v-if="position==='学生'">
                <div class="apply_request_head">
                    <van-cell title="请假记录">
                        <div @click="$router.push({path:'/applyRecords'})">更多</div>
                    </van-cell>
                </div>
                <div class="apply_request_list" v-if="leaveList.length>0">
                    <van-cell v-for="leave in leaveList" :key="leave.id" @click="$router.push({path:'/viewLeave',query:{leaveId:leave.id}})">
                        <div slot="title">
                            {{leave.reason}}
                            <van-tag round plain type="primary">{{leave.leaveType}}</van-tag>
                            <van-tag round :type="leave.status===1?'primary':leave.status===2?'warning':leave.status===3?'danger':leave.status===4?'success':leave.status===5?'default':''">{{leave.status===1?'待审批':leave.status===2?'审批中':leave.status===3?'驳回':leave.status===4?'通过':leave.status===5?'已撤销':''}}</van-tag>
                        </div>
                        <div slot="label">
                            {{leave.datetimeFrom + ' 至 ' + leave.datetimeTo}}
                        </div>
                    </van-cell>
                </div>
                <div class="no_data" v-else>
                    暂无数据
                </div>
            </div>
            <div class="access_request" v-if="position==='学生'">
                <div class="access_request_head">
                    <van-cell title="开门记录">
                        <div @click="$router.push({path:'/accessRecords'})">更多</div>
                    </van-cell>
                </div>

                <div class="access_request_list" v-if="passingRecordList.length>0">
                    <van-cell v-for="passingRecord in passingRecordList" :key="passingRecord.id" :title="passingRecord.passedAt+'请求'+(passingRecord.direction===1?'进门':passingRecord.direction===2?'出门':'未知')">
                        <div>
                            {{passingRecord.abnormalType===0?'成功':passingRecord.abnormalType===10001?'人证不匹配':passingRecord.abnormalType===10002?'人卡不匹配':passingRecord.abnormalType===10003?'人码不匹配':passingRecord.abnormalType===20001?'访客不在有效期内':passingRecord.abnormalType===20002?'不在通行时间内':passingRecord.abnormalType===30001?'无效身份证':passingRecord.abnormalType===30002?'无效IC卡':passingRecord.abnormalType===30003?'无效二维码':passingRecord.abnormalType===40001?'体温异常':'未知'}}
                        </div>
                    </van-cell>
                </div>
                <div class="no_data" v-else>
                    暂无数据
                </div>
            </div>

            <div class="apply_request" v-if="position!=='学生'">
                <div class="apply_request_head">
                    <van-cell title="审批记录">
                        <div @click="$router.push({path:'/approveRecords'})">更多</div>
                    </van-cell>
                </div>
                <div class="apply_request_list" v-if="approvalList.length>0">

                    <van-cell v-for="item in approvalList" :key="item.id" :label="'申请时间:'+item.requestedAt" @click="getApproval(item)">
                        <template #title>
                            <span>{{item.requesterName}}</span>
                            <van-tag round plain type="primary">{{item.typeName}}</van-tag>
                            <van-tag type="primary" round v-if="item.approvalStatus==='1'">待审批</van-tag>
                            <van-tag type="primary" plain round v-else>已审批</van-tag>
                        </template>
                    </van-cell>
                </div>
                <div class="no_data" v-else>
                    暂无数据
                </div>
            </div>
        </div>
        <div class="footer">
            <van-tabbar v-model="active">
                <van-tabbar-item icon="wap-home">首页</van-tabbar-item>
                <van-tabbar-item icon="manager" @click="goToMe">我的</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
  import {reqGetPassingRecordByUserId,reqApprovalRecord} from '../api/user-api'
  import {reqGetWxLeave,reqGetWxIndexUser} from "../api";
  import {downloadFileURL} from "../api/login-api";
  export default {
    data() {
      return {
          position:null,
          active:0,
          nowDate:'',
          userInfo:{},
          downloadFileURL:downloadFileURL,
          passingRecordList:[],
          approvalList:[],
          leaveList:[],
          leavePage: {
              pageNum: 1,
              pageSize: 3,
              total:0
          },
          page: {
              pageNum: 1,
              pageSize: 5,
              total:0
          },
      };
    },
    methods: {
        getApproval(item){
            if(item.approvalStatus==='1'){
                this.$router.push({path:'/leaveDetails',query:{leaveId:item.id, from: '/index'}});
                //this.$router.push({path:'/leaveDetails', name: 'leaveDetails', query:{leaveId:item.id}, params:{from: '/index'}});
            }else{
                this.$router.push({path:'/viewLeave?leaveId='+item.id})
            }
        },
        goToMe(){
            this.$router.push({path:"/userCenter"});
        },
        goLeave(){
            if(this.position==='学生'){
                this.$router.push({path:"/applyRequest"})
            }else{
                this.$router.push({path:"/approveRecords"})
            }
        },

        //获取审批记录
        getApprovalRecord(){
            reqApprovalRecord().then(res=>{
                if(res.data.code===1){
                    this.approvalList=res.data.data;
                }else{
                    this.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                }
            }).catch(() => {
                this.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
            });
        },
        //查询用户信息
        getUserOne(){
            reqGetWxIndexUser().then(res=>{
                if(res.data.code===1){
                    this.userInfo=res.data.data;
                }else{
                    this.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                }
            }).catch(()=>{
                this.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
            })
        },
        //开门记录
        getPassingRecord(){
            var that=this;
            reqGetPassingRecordByUserId({ pageNum: that.page.pageNum, pageSize: that.page.pageSize}).then(res=>{
                if(res.data.code===1){
                    that.passingRecordList=res.data.data.records;
                }else{
                    that.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                }
            }).catch(()=> {
                that.$notify({type: 'danger', duration: '1000', message: "查询失败"});
            })
        },
        currentTime() {
            setInterval(this.formatDate, 500);
        },
        formatDate() {
//            let date = new Date();
//            let year = date.getFullYear(); // 年
//            let month = date.getMonth() + 1<10?"0"+(date.getMonth() + 1):date.getMonth() + 1; // 月
//            let day = date.getDate(); // 日
//            let week = date.getDay(); // 星期
//            let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
//            let hour = date.getHours(); // 时
//            hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
//            let minute = date.getMinutes(); // 分
//            minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
//            let second = date.getSeconds(); // 秒
//            second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
            this.$moment.locale('zh-cn');
            this.nowDate = this.$moment().format('YYYY年MM月DD日 dddd HH:mm:ss');
        },
        //请假记录
        getLeave(){
            var that=this;
            reqGetWxLeave({ pageNum: that.leavePage.pageNum, pageSize: that.leavePage.pageSize}).then(res=>{
                if(res.data.code===1){
                    that.leaveList=res.data.data.records;
                    that.leaveList.forEach((item) => {
                        item.datetimeFrom = that.$moment(item.datetimeFrom).format("YYYY-MM-DD HH:mm");
                        item.datetimeTo = that.$moment(item.datetimeTo).format("YYYY-MM-DD HH:mm");
                    })
                }else{
                    that.$notify({ type: 'danger', duration: '1000', message: "查询失败" });
                }
            }).catch(()=> {
                that.$notify({type: 'danger', duration: '1000', message: "查询失败"});
            })
        },
        loadData(){
            this.position=localStorage.getItem('position');
            this.currentTime();
            this.getUserOne();
            if(this.position==='学生'){
                this.getPassingRecord();
                this.getLeave();
            }else{
                this.getApprovalRecord();
            }
        }
    },
      // 销毁定时器
      beforeDestroy() {
          if (this.formatDate) {
              clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
          }
      },
      created() {
          this.loadData();
      },
      watch: {
          $route: {
              immediate: true, // 一旦监听到路由的变化立即执行
              handler(to, from) {
                  this.loadData();
              },
          }
      },
    mounted(){
    }
  };
</script>

<style scoped>

</style>