/**
 * Created by TapFun on 2021/2/4.
 */
import axios from 'axios';
import qs from 'qs';
import { SERVER_HOST } from './../common/portConfig';
import { Dialog } from 'vant'

var instance = axios.create({

});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = localStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    }
);
instance.interceptors.response.use(
    response => {
        if (response.data.code === 409) {
            Dialog.alert({
                title: '温馨提示',
                message: '登录状态失效，请重新登录',
            }).then(() => {
                localStorage.removeItem("Authorization");
                window.location.href = '/';
            });
            return response;

        } else {
            return response
        }
    }, err => {
        return Promise.reject(err)
    }
);


// 通过id获取用户信息
export const reqUserExternalOne = params => { return instance.get(`${SERVER_HOST}/userExternal/one`, { params: params }) };


//帮助中心查看页面
export const reqGetHelpCenter = params => {
    return instance.get(`${SERVER_HOST}/helpCenter/view`, { params: params })
};
//获取审批记录
export const reqApprovalRecord = params => {
    return instance.get(`${SERVER_HOST}/leave/approveRecord`, { params: params })
};
//获取请假详情
export const reqLeaveDetail = params => {
    return instance.get(`${SERVER_HOST}/leave/detail`, { params: params })
};
//查看请假详情
export const reqLeaveView = params => {
    return instance.get(`${SERVER_HOST}/leave/mobileView`, { params: params })
};
//获取个人请假列表
export const reqLeaveList = params => {
    return instance.get(`${SERVER_HOST}/leave/list`, { params: params })
}

//通过用户id查询开门记录列表
export const reqGetPassingRecordByUserId = params => {
    return instance.get(`${SERVER_HOST}/passingRecord/getPassingRecordByUserId`, { params: params })
};

//通过用户id查询抄送记录列表
export const reqGetLeaveCcByUserId = params => {
    return instance.get(`${SERVER_HOST}/leaveCc/getLeaveCcByUserId`, { params: params })
};
//  提交审批意见
export const reqSubmitResult = params => {
    return instance.post(`${SERVER_HOST}/leave/approval`, params)
};
//通过用户id查询抄送记录列表
export const reqGetApprovalResults = params => {
    return instance.get(`${SERVER_HOST}/leave/approvalResults`, { params: params })
};
//获取全部部门
export const reqGetDepatrmentList = params => {
    return instance.get(`${SERVER_HOST}/department/deptList`, { params: params })
};
//根据部门获取用户
export const reqDepatrmentUser = params => {
    return instance.get(`${SERVER_HOST}/userExternal/users`, { params: params })
};
//撤销申请
export const reqapplicationCanceled = params => {
    return instance.get(`${SERVER_HOST}/leave/applicationCanceled`, { params: params })
};
//获取当前用户是否拥有该假条
export const reqgetBellyFlag = params => {
    return instance.get(`${SERVER_HOST}/leave/bellyFlag`, { params: params })
};
// 用户修改密码
export const updateExternalUserPwd = params => {
    return instance.post(`${SERVER_HOST}/userExternal/updateExternalUserPwd`, params)
};